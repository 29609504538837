import {
  PiWizardActions,
  PiWizardActionTypeEnum,
  PiWizardConditions, PiWizardConditionTypeEnum,
  PiWizardConfig
} from "@/components/Wizard/WizardNew/types";
import { cloneDeep, get, isEmpty, isObject, merge, set, unset } from "lodash";
import Vue from "vue";

export function prepareSteps(config: PiWizardConfig, initModel?: any) {
  return Object.keys(config.pages).reduce((obj: Record<string, any>, stepGuid, index) => {
    if (initModel && index === 0) {
      obj[stepGuid] = initModel
    } else {
      obj[stepGuid] = {}
    }

    return obj
  }, {})
}

export function applyAction(action: PiWizardActions, model: any, customValue?:any) {
  const modelCopy = cloneDeep(model)

  if (action.type === PiWizardActionTypeEnum.Set) {
    action.items.forEach((actionItem) => {
      set(modelCopy, actionItem.path, customValue ?? actionItem.value)
    })
  } else if (action.type === PiWizardActionTypeEnum.Unset) {
    action.items.forEach((actionItem) => {
      unset(modelCopy, actionItem.path)
    })
  } else {
    merge(modelCopy, customValue ?? action.item)
  }

  return modelCopy
}

export function reactiveSet(object: any, path: string, value:any) {
  const objectCopy = cloneDeep(object)
  const decomposedPath = path.split('.');
  const root = decomposedPath[0];

  if (root === undefined) {
    return objectCopy;
  }

  if (!objectCopy.hasOwnProperty(root)) {
    Vue.set(objectCopy, root, {})
  }

  value = decomposedPath.length <= 1 ? value : reactiveSet(objectCopy[root], decomposedPath.slice(1).join('.'), value);

  Vue.observable(objectCopy)
  Vue.set(objectCopy, root, value)

  return objectCopy
}

export function resolveCondition(conditions: Array<Array<PiWizardConditions>>, model: any): boolean {
  const result: Array<boolean> = []

  conditions.forEach(conditionGroup => {
    const groupResult: Array<boolean> = []

    conditionGroup.forEach((condition) => {
      if (condition.type === PiWizardConditionTypeEnum.Empty) {
        groupResult.push(doEmptyLogic(condition.fieldPath, model))
      }
      if (condition.type === PiWizardConditionTypeEnum.NotEmpty) {
        groupResult.push(doNotEmptyLogic(condition.fieldPath, model))
      }
    })

    result.push(groupResult.some(value => value))
  })

  return result.every(value => value)
}

function doEmptyLogic(value: string | Array<string> | object, model: any): boolean {
  const results: Array<boolean> = []

  if (typeof value === 'string') {
    if (!isObject(get(model, value))) {
      results.push(!get(model, value))
    } else {
      results.push(!isEmpty(get(model, value)))
    }
  }

  if (Array.isArray(value)) {
    value.forEach(path => {
      if (!isObject(get(model, path))) {
        results.push(!get(model, path))
      } else {
        results.push(!isEmpty(get(model, path)))
      }
    })
  }

  return results.every(result => result)
}

function doNotEmptyLogic(value: string | Array<string> | object, model: any): boolean {
  const results: Array<boolean> = []

  if (typeof value === 'string') {
    if (!isObject(get(model, value))) {
      results.push(Boolean(get(model, value)));
    } else {
      results.push(isEmpty(get(model, value)));
    }
  }

  if (Array.isArray(value)) {
    value.forEach(path => {
      if (!isObject(get(model, path))) {
        results.push(get(model, path))
      } else {
        results.push(isEmpty(get(model, path)))
      }
    })
  }

  return results.every(result => result)
}
