










import { Component, Vue } from 'vue-property-decorator'
import NewWizard from "@/components/Wizard/WizardNew/components/NewWizard.vue";

@Component({
  components: {
    NewWizard,
  },
})
export default class WizardPage extends Vue {
  isWizardOpen = false

  mounted() {
    this.$baseTemplate.saveButton.hide()
  }
}
